/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Platzi from "@components/sharedComponents/customersLogos/logos/Platzi.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Prestamype from "@components/sharedComponents/customersLogos/logos/Prestamype.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import Synlab from "@components/sharedComponents/customersLogos/logos/Synlab.svg"
import Vvoden from "@components/sharedComponents/customersLogos/logos/Vvoden.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import Eriza from "@components/sharedComponents/customersLogos/logos/3eriza.svg"
import E4CC from "@components/sharedComponents/customersLogos/logos/E4CC.svg"
import Farenet from "@components/sharedComponents/customersLogos/logos/Farenet.svg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageFunctionalities/pageContactCenterIA/images/ImageAccordionLeftOne.webp"
import ImageAccordionLeftTwo from "@components/pageFunctionalities/pageContactCenterIA/images/ImageAccordionLeftTwo.webp"
import ImageAccordionLeftThree from "@components/pageFunctionalities/pageContactCenterIA/images/ImageAccordionLeftThree.webp"
import ImageAccordionLeftFour from "@components/pageFunctionalities/pageContactCenterIA/images/ImageAccordionLeftFour.webp"
import ImageAccordionLeftFive from "@components/pageFunctionalities/pageContactCenterIA/images/ImageAccordionLeftFive.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageContactCenterIA.jpg"

// logos testimonios
import CulqiTes from "@components/sharedComponents/testimonies/images/logos/culqi.svg"
import NetcallTes from "@components/sharedComponents/testimonies/images/logos/netcall.svg"
import SynlabTes from "@components/sharedComponents/testimonies/images/logos/synlab.svg"
import IttsaBusTes from "@components/sharedComponents/testimonies/images/logos/ittsaBus.svg"
import EnseñaTes from "@components/sharedComponents/testimonies/images/logos/enseña.svg"

//foto testimonio desktop
import culqiDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/culqi.png"
import netcallDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/netcall.png"
import synlabDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/synlab.png"
import ittsaBusDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/ittsaBus.png"
import enseñaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/enseña.png"

//foto testimonio desktop
import culqiMobile from "@components/sharedComponents/testimonies/images/fotosMobile/culqi.png"
import netcallMobile from "@components/sharedComponents/testimonies/images/fotosMobile/netcall.png"
import synlabMobile from "@components/sharedComponents/testimonies/images/fotosMobile/synlab.png"
import ittsaBusMobile from "@components/sharedComponents/testimonies/images/fotosMobile/ittsaBus.png"
import enseñaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/enseña.png"

import { TitleUnderlineStartOne, TitleUnderlineStartTwo } from "@components/pageFunctionalities/pageContactCenterIA/components/titleUnderline"

const titleStart = (<p className="guide__title"> Automatiza tu Contact Center con tecnología
  omnicanal <TitleUnderlineStartOne underline="impulsada" />  <TitleUnderlineStartTwo underline="por IA" />
</p>
)

const descripcionStart = (<p> Automatiza procesos de atención, marketing y ventas. Mejora la experiencia del cliente y reduce costos con una plataforma intuitiva y lista para implementarse.</p>
)


const data = {
  start: {
    title: titleStart,
    textbody: descripcionStart
  },
  customersDesktop: [
    Rimac,
    Culqui,
    Platzi,
    Alfin,
    Prestamype,
    Civa,
    Synlab,
    Vvoden,
    Conexo,
    Eriza,
    E4CC,
    Farenet
  ],
  customersTablet: {
    arrayOne: [Rimac,
      Culqui,
      Platzi,
      Alfin,
      Prestamype,
      Civa,],
    arrayTwo: [
      Synlab,
      Vvoden,
      Conexo,
      Eriza,
      E4CC,
      Farenet],
  },
  contacts: {
    title: "Potencia tus operaciones de Contact Center con tecnología cloud e IA",
    textbody: "Cuéntanos sobre tus operaciones outbound o inbound para que nuestros asesores te brinden un feedback que te permita mejorar tus servicios.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  accordionRight: [
    {
      title: "Vista omnicanal",
      description: "<p>Centraliza tus principales canales de comunicación, como llamadas, WhatsApp, redes sociales y correo. Obtén una vista 360 de tus clientes y sus interacciones, automatizando acciones que logren mejorar tus tiempos de respuesta y niveles de servicio.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "IA y automatizaciones",
      description: "<p>Analiza en tiempo real el comportamiento del cliente, optimizando sus interacciones. Configura workflows y enrutamientos inteligentes que mejores tus principales métricas de servicio, marketing o ventas.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Integraciones escalables",
      description: "<p>Beex API Cloud permite integrar ilimitadas herramientas empresariales. Nuestra interfaz asegura conectividad fluida y sincronización bidireccional, mejorando la eficiencia operativa de tu equipo.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Crea campañas en simples pasos",
      description: "<p>Nuestra interfaz intuitiva permite diseñar y lanzar campañas de llamadas o mensajes en minutos. Con herramientas drag and drop, plantillas y opciones personalizables, maximizas la tasa de contacto.</p>",
      image: ImageAccordionLeftFour
    },
    {
      title: "Contactabilidad automatizada",
      description: "<p>Programa llamadas y mensajes en horarios óptimos para maximizar el contacto. Reactiva contactos no respondidos y ajusta la frecuencia según el comportamiento del cliente en canales como WhatsApp y llamadas.</p>",
      image: ImageAccordionLeftFive
    },
  ],
  bannerVideo: {
    title: "Mira cómo Beex puede simplificar la gestión de tu Contact Center",
    description: "<p>Entérate cómo puedes optimizar las operaciones de tu Contact Center utilizando nuestra plataforma.</p>",
    image: imageVideo
  },
  testimonies: [
    {
      image: CulqiTes,
      title:
        "Con Beex, hemos logrado mejorar la contactabilidad con nuestros leads en +65%",
      textbody:
        "Culqi, la fintech que revoluciona la forma de hacer pagos digitales, usa Beex para automatizar sus procesos de telemarketing, por canal telefónico y WhatsApp.",
      name: "Paul Vera",
      post: "Jefe de Telemarketing",
      link: "/casos-de-exito/culqi/",
      imgDesktop: culqiDesktop,
      imgMobile: culqiMobile,
      metrics: [
        {
          metric: "+65%",
          descripcion: "mejoró su contactabilidad con sus leads",
          color: "#FFB800",
        },
        {
          metric: "x8",
          descripcion: "aumentó sus ventas mensuales",
          color: "#5831D8",
        },
        {
          metric: "x3",
          descripcion: "redujo el tiempo de recorrido de bases",
          color: "#00CA56",
        },
      ],
    },
    {
      image: NetcallTes,
      title:
        "Con Beex y su marcador predictivo, hemos duplicado nuestras ventas mensuales",
      link: "/casos-de-exito/netcall-peru/",
      industrie: "Contact Center & BPO",
      backgroundIndustrie: "#EC4600",
      backgroundFondo: "#EC460033",
      metrics: [
        {
          metric: "+25%",
          descripcion: "aumentó su contactabilidad",
          color: "#FFB800",
        },
        {
          metric: "+20%",
          descripcion: "mejoró su tasa de ocupación",
          color: "#5831D8",
        },
        {
          metric: "x2",
          descripcion: "duplicaron sus ventas mensuales",
          color: "#00CA56",
        },
      ],
    },
    {
      image: SynlabTes,
      title:
        "Beex nos ha permitido resolver el 20% de  atenciones sin necesidad de agentes",
      link: "/casos-de-exito/synlab/",
      industrie: "Salud",
      backgroundIndustrie: "#092F57",
      backgroundFondo: "#092F5733",
      metrics: [
        {
          metric: "95%",
          descripcion: "mejoró su tiempo de primera respuesta",
          color: "#FFB800",
        },
        {
          metric: "20%",
          descripcion: "tickets cerrados sin necesidad de un agente",
          color: "#5831D8",
        },
        {
          metric: "20 seg",
          descripcion: "disminuyeron sus respuestas telefónicas",
          color: "#00CA56",
        },
      ],
    },
    {
      image: IttsaBusTes,
      title:
        "Con Beex, mejoramos en 22% la satisfacción de nuestros usuarios al reagendar sus viajes",
        link: "/casos-de-exito/ittsa-bus/",
        industrie: "Transportes",
        backgroundIndustrie: "#F79531",
        backgroundFondo: "#F7953133",
      metrics: [
        {
          metric: "95%",
          descripcion: "mejoraron su tiempo de primera respuesta",
          color: "#FFB800",
        },
        {
          metric: "22%",
          descripcion: "mejoraron satisfacción en reprogramación de viajes",
          color: "#5831D8",
        },
      ],
    },
    {
      image: EnseñaTes,
      title:
        "Con Beex, hemos aumentado en +50% la contactabilidad con potenciales donantes",
        link: "/casos-de-exito/ensena-peru/",
        industrie: "ONG",
        backgroundIndustrie: "#FF0028",
        backgroundFondo: "#FF002833",
      metrics: [
        {
          metric: "40%",
          descripcion: "aumentaron sus donantes",
          color: "#FFB800",
        },
        {
          metric: "+50%",
          descripcion: "mejoró su contactabilidad",
          color: "#5831D8",
        },
      ],
    },
  ],

  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Latam. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
}

export default data
